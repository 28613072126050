<template>
<v-app id="inspire">
    <div class="px-4">
        <loading :is-show="loadingDialog" :is-done="loadingFinished" :is-html="isHtml" :message="loadingMessage" v-on:close-dialog="closeDialog" />
        <details-form :is-show="isShowDetails" :details="details" v-on:close-dialog="closeDialog" />
        <v-container class="container--fluid grid-list-md text-center">
            <h1>Tickets</h1>
        </v-container>
        <v-card elevation="2" outlined shaped tile class="pa-1" v-if="hasPermission('ROLE_TDM_VIEW_TICKET')">
            <div class="d-flex flex-no-wrap justify-space-between">
                <v-card-title>
                    <v-container grid-list-md text-xs-center>
                        <v-layout row wrap>
                            <v-flex xs12 sm6>
                                <v-text-field label="Code" v-model="query.code" single-line outlined @change="search($event, 'code')" data-cy="module-code"></v-text-field>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card-title>
            </div>
            <div class="d-flex flex-no-wrap justify-space-between">
                <v-card-title>
                    <v-container grid-list-md text-xs-center>
                        <v-layout wrap>
                            <v-flex xs12 sm6 v-if="hasPermission('ROLE_TDM_VIEW_TICKET')">
                                <v-btn :block="$vuetify.breakpoint.mdAndDown" class="mt-2" color=success @click="queryData(true)" data-cy="button-search">
                                    <v-icon dark left> mdi-magnify</v-icon> Search
                                </v-btn>
                            </v-flex>
                            <v-flex xs12 sm6 v-if="hasPermission('ROLE_TDM_CREATE_TICKET')">
                                <v-btn :block="$vuetify.breakpoint.mdAndDown" class="mt-2" color="success" @click.stop="showTicketForm('CREATED')" data-cy="button-create">Create Ticket</v-btn>
                                <ticket-form ref="TicketForm" :selected-item="selectedTicket" :is-show="showFormDialog" :form-type="ticketFormType" :merchants="merchants" :terminals="terminals" :users="users" v-on:close-dialog="closeDialog" v-on:save-ticket="saveTicket" />
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card-title>
            </div>
        </v-card>

        <v-data-table :hide-default-header="true" :headers="headers" :items="displayTerminals"
            :loading="loading" :page="page" :pageCount="numberOfPages" :options.sync="options"
            :server-items-length="totalTerminals" class="mb-14"
            :footer-props="{'items-per-page-options': [10, 20, 50]}">
            <template v-slot:header="{ props: { headers } }">
                <thead>
                    <tr :colspan="headers.length">
                        <th v-for="header in headers" :key="header.text">
                            <span class="d-flex justify-center">{{header.text}}</span>
                        </th>
                    </tr>
                </thead>
            </template>
            <template v-slot:body="{ items }">
                <tbody>
                    <tr :colspan="headers.length" v-for="item in items" :key="item.id">
                        <td> <span class="d-flex justify-center"> {{ item.code }} </span></td>
                        <td> <span class="d-flex justify-center"> {{ item.issue }} </span></td>
                        <td> <span class="d-flex justify-center"> {{ item.problem }} </span></td>
                        <td> <span class="d-flex justify-center"> {{ item.status }} </span></td>
                        <td>
                            <span class="d-flex justify-center">
                                <v-btn fab dark x-small color="green" class="mr-2" @click.stop="viewTicket(item)">
                                    <v-icon dark medium>mdi-magnify-plus</v-icon>
                                </v-btn>
                                <v-btn fab dark x-small color="green" @click.stop="showTicketForm('UPDATE', item)" v-if="hasPermission('ROLE_TDM_UPDATE_TICKET')">
                                    <v-icon dark medium>mdi-pencil</v-icon>
                                </v-btn>
                            </span>
                        </td>
                    </tr>
                </tbody>
            </template>
            <template v-slot:no-results>
                <v-alert :value="true" color="error" icon="warning">
                    Found no results.
                </v-alert>
            </template>
        </v-data-table>
    </div>
</v-app>
</template>

<script>
import API from '@/api/API.js'
import Loading from '@/views/components/Loading.vue'
import TicketForm from '@/views/components/TicketForm.vue'
import DetailsForm from '@/views/components/DetailsForm.vue'
import {
    mapGetters
} from 'vuex';

export default {
    name: 'Tickets',
    components: {
        Loading,
        TicketForm,
        DetailsForm
    },
    data() {
        return {
            //filter/search variables
            filterQry: {
                qryName: "",
            },
            headers: [],
            displayTickets: [],
            remainderTickets: [],
            // authorities: [],
            merchants: [],
            terminals: [],
            users: [],
            loading: true,
            loadingMessage: "",
            loadingDialog: false,
            loadingFinished: false,
            ticketFormType: 'CREATE',
            selectedTicket: {},
            page: 1,
            totalTickets: 0,
            numberOfPages: 0,
            options: {
                rowsPerPage: 10
            },
            details: {
                title: '',
                item: {}
            },
            query: {
                code: ''
            },
            isShowDetails: false,
            showFormDialog: false,
            isHtml: false
        }
    },
    watch: {
        options: {
            handler() {
                console.log('1')
                this.queryData()
                this.setData()
            },
        },
        deep: true,
    },
    computed: {
        //get role attributes
        ...mapGetters([
            'attributes',
            'user',
            'authorities'
        ]),
    },
    async mounted() {
        this.setHeaders()
        //this.queryData()
    },
    methods: {
        hasPermission(authority) {
          if(this.authorities.some(x => x.name === authority)){
            return true
          }
          return false
        },
        async queryData(isSearch = false) {
            this.loading = true
            if (isSearch) {
                this.options.page = 1
            }
            const {
                page,
                itemsPerPage
            } = this.options;
            let pageNumber = page - 1;
            try {
                const limit = itemsPerPage
                const listResponse = await API.getTickets(pageNumber, limit, this.query)
                if (!listResponse || listResponse.error) {
                    //error getting data
                    console.log(`${listResponse.error}`)
                } else {
                    var filteredList = listResponse.tickets
                    if (page > 1 && this.remainderTickets.length > 0) {
                        filteredList = this.remainderTickets.concat(filteredList)
                    }
                    this.totalTickets = listResponse.totalItems
                    const end = filteredList.length < itemsPerPage ? filteredList.length : itemsPerPage
                    this.displayTickets = filteredList.slice(0, end)
                    this.remainderTickets = filteredList.length < itemsPerPage ? [] : filteredList.slice(end)
                    this.numberOfPages = listResponse.pageCount
                    // const hasMoreResponse = await API.getTickets(this.totalTickets, 1, this.query)
                    // if (!hasMoreResponse || hasMoreResponse.error) {
                    //     //error getting data
                    //     console.log(`${hasMoreResponse.error}`)
                    // } else {
                    //     var hasMoreList = hasMoreResponse.tickets
                    //     if(hasMoreList.length > 0) {
                    //       this.totalTickets = this.totalTickets + 1
                    //       this.numberOfPages = this.numberOfPages + 1
                    //     }
                    // }
                }
                this.loading = false
            } catch (e) {
                console.log(e)
                this.loading = false
            }
        },
        async setData() {
        //   try {
        //         const listResponse = await API.getAllMerchants()
        //         if (!listResponse || listResponse.error) {
        //             //error getting data
        //             console.log(`${listResponse.error}`)
        //         } else {
        //             this.merchants = listResponse.merchants
        //         }
        //     } catch (e) {
        //         console.log(e)
        //     }
            try {
                const listResponse = await API.getAllTerminals()
                if (!listResponse || listResponse.error) {
                    //error getting data
                    console.log(`${listResponse.error}`)
                } else {
                    this.terminals = listResponse.terminal
                }
            } catch (e) {
                console.log(e)
            }
            try {
                const listResponse = await API.getAllAssignedToUsers()
                if (!listResponse || listResponse.error) {
                    //error getting data
                    console.log(`${listResponse.error}`)
                } else {
                    this.users = listResponse.users
                }
            } catch (e) {
                console.log(e)
            }
        },
        setHeaders() {
            var headers = [
                {
                    text: 'Code',
                    value: 'code'
                },
                {
                    text: 'Issue',
                    value: 'issue'
                },
                {
                    text: 'Problem',
                    value: 'problem'
                },
                {
                    text: 'Status',
                    value: 'status'
                },
                {
                    text: 'Actions',
                    value: 'controls',
                    sortable: false
                }
            ]
            this.headers = headers
        },
        showTicketForm(what, item = {}) {
            this.ticketFormType = what
            this.selectedTicket = item
            this.showFormDialog = true
        },
        closeDialog(type) {
            console.log('type:', type);
            if (type === 'details') {
                this.details.title = ''
                this.details.item = {}
                this.isShowDetails = false
            } else if (type === 'CREATED' || type === 'UPDATE') {
                this.showFormDialog = false
            } else if (type === 'loading') {
                this.loadingDialog = false
                this.queryData();
            } else if (type === 'loading-error') {
                this.loadingDialog = false
            }
        },
        async saveTicket(value) {
            this.loadingDialog = true
            this.isHtml = true
            this.loadingFinished = false
            if (value) {
              console.log(value)
                if (value.formType === 'CREATED') {
                    this.loadingMessage = `Creating new ticket <strong><i>${value.code}</i></strong>`
                    setTimeout(async () => {
                        const createResponse = await API.createTicket(value)
                        console.log(createResponse)
                        this.loadingFinished = true
                        if (!createResponse || createResponse.error) {
                            this.isHtml = false
                            this.loadingMessage = createResponse.error
                        } else {
                            this.loadingMessage = `Successfully created ticket <strong><i>${value.code}</i></strong>`
                            this.showFormDialog = false
                            this.$refs.TicketForm.resetForm()
                        }
                    }, 2000);

                } else if (value.formType === 'UPDATE') {
                    this.loadingMessage = `Updating ticket <strong><i>${value.code}</i></strong>`
                    setTimeout(async () => {
                        const updateResponse = await API.updateTicket(value)
                        this.loadingFinished = true
                        if (!updateResponse || updateResponse.error) {
                            this.isHtml = false
                            this.loadingMessage = updateResponse.error
                        } else {
                            this.loadingMessage = `Successfully updated ticket <strong><i>${value.code}</i></strong>`
                            this.showFormDialog = false
                            this.$refs.TicketForm.resetForm()
                        }
                    }, 2000);
                } else {
                    this.loadingMessage = "Invalid command"
                    this.loadingFinished = true
                }
            } else {
                this.loadingMessage = "Error processing data."
                this.loadingFinished = true
            }
        },
        viewTicket(ticket) {
            this.details.title = ticket.code
            this.details.item = ticket
            this.isShowDetails = true
        },
        search(event, type) {
            this.query[type] = event
        }
    }
};
</script>

<style scoped>
.v-data-table {
    max-width: 100%
}
</style>
