<template>
<v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card ref="formCard">
            <v-card-title>
                <span class="headline" v-if="formType === 'UPDATE'">Update New Ticket</span>
                <span class="headline" v-else>Create New Ticket</span>
            </v-card-title>
            <v-card-text>
                <v-form ref="formEntry">
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12>
                                <v-text-field label="Code/Ticket Number*" :readonly="formType === 'UPDATE'"   v-model="form.code" required :rules="[rules.requiredField]" data-cy="form-code"></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-autocomplete clearable chips :items="terminalList" item-text="terminalId" item-value="terminalId" label="Terminal Id*" v-model="form.terminalId" required :rules="[rules.requiredField]" return-object data-cy="form-terminal-id">
                                </v-autocomplete>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field label="Type of Issue*"  v-model="form.issue" required :rules="[rules.requiredField]" data-cy="form-issue"></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field label="Problem"  v-model="form.problem" data-cy="form-problem"></v-text-field>
                            </v-flex>
                            <v-flex xs12 >
                                 <v-select :items="statusList" v-model="form.status" label="Status" @change="getStatus" data-cy="form-status"></v-select>
                            </v-flex>  
                            <v-flex xs12>
                                <v-text-field label="Parts Replaced"  v-model="form.partsReplaced" data-cy="form-part-replaced"></v-text-field>
                            </v-flex>
                             <v-flex xs12>
                                <v-autocomplete clearable chips :items="userList" item-text="username" item-value="username" label="Reported By*" v-model="form.reportedBy" required :rules="[rules.requiredField]" return-object data-cy="form-reported-by">
                                </v-autocomplete>
                            </v-flex>
                             <v-flex xs12>
                                <v-autocomplete clearable chips :items="userList" item-text="username" item-value="username" label="Assigned To"  v-model="form.assignedTo" return-object data-cy="form-assigned-to">
                                </v-autocomplete>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field label="Action taken by Engr."  v-model="form.action" data-cy="form-action"></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field label="Action taken by ECOD"  v-model="form.ecodAction" data-cy="form-ecod-action"></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                 <v-menu v-model="showServiceSheduleMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y max-width="290px" min-width="290px" data-cy="form-show-service">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field label="Service Schedule"  prepend-icon="mdi-calendar" readonly :value="displayServiceSchedule" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker locale="en-in" v-model="form.serviceSchedule" no-title @input="showServiceSheduleMenu = false" ></v-date-picker>
                                </v-menu>
                            </v-flex>
                            <v-flex xs12>
                                 <v-menu v-model="showDateMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y max-width="290px" min-width="290px" data-cy="form-show-date-menu">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field label="Date Resolved"  prepend-icon="mdi-calendar" readonly :value="displayDateResolved" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker locale="en-in" v-model="form.dateResolved" no-title @input="showDateMenu = false" ></v-date-picker>
                                </v-menu>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field label="Remarks"  v-model="form.remarks" data-cy="form-remarks"></v-text-field>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-form>
                <small>*indicates required field</small>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text v-on:click="close" data-cy="close-button">Close</v-btn>
                <v-btn color="blue darken-1" text v-on:click="save" data-cy="save-button">Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-layout>
</template>

<script>
export default {
    props: {
        isShow: Boolean,
        formType: String,
        loadingDialog: Boolean,
        selectedItem: Object,
        terminals: Array,
        users: Array
    },
    computed: {
        populatedForm() {
            return {
                code: this.form.code,
                issue: this.form.issue,
                problem: this.form.problem,
                remarks: this.form.remarks,
                terminalId: this.form.terminalId,
                status: this.form.status,
                partsReplaced: this.form.partsReplaced,
                reportedBy: this.form.reportedBy,
                assignedTo: this.form.assignedTo,
                action: this.form.action,
                ecodAction: this.form.ecodAction,
                formType: this.formType,
                serviceSchedule: this.form.serviceSchedule,
                dateResolved: this.form.dateResolved,
                
            }
        },
        displayServiceSchedule() {
            return this.form.serviceSchedule
        },
        displayDateResolved() {
            return this.form.dateResolved
        }
    },
    watch: {
        isShow: {
            handler(value) {
                this.dialog = value
            }
        },
        loadingDialog: {
            handler(value) {
                this.loading = value
            },
        },
        dialog: {
            handler(value) {
                if (value === true) {
                    this.initializeForm()
                }
            }
        },
        merchants: {
            handler(value) {
                this.merchantList = value
            }
        },
        terminals: {
            handler(value) {
                this.terminalList = value
            }
        },
         users: {
            handler(value) {
                this.userList = value
            }
        },
        deep: true,
    },
    data() {
        return {
            dialog: this.isShow ?? false,
            loading: this.loadingDialog ?? false,
            merchantList: this.merchants ?? [],
            terminalList: this.terminals ?? [],
            userList: this.users ?? [],
            showDateMenu: false,
            showServiceSheduleMenu: false,
            statusList: ['RESOLVED','UNRESOLVED','PENDING'],
            form: {
              merchants: [],
              terminalId: {},
              reportedBy: {},
              assignedTo: {},
            },
            rules: {
                requiredField: v => !!v || "Required field.",
                requiredSelection: v => !!v && v.length > 0 || "Required at least one selection",
            }
        }
    },
    mounted() {
        this.initializeForm()
    },
    methods: {
        close() {
            this.dialog = false
            this.$refs.formEntry.reset()
            this.$refs.formCard.$el.scrollIntoView({
                behavior: 'smooth'
            })
            this.$emit('close-dialog', this.formType)
        },
        save() {
            if (this.$refs.formEntry.validate()) {
                this.$emit('save-ticket', this.populatedForm)
                this.$refs.formCard.$el.scrollIntoView({
                    behavior: 'smooth'
                })
            }
        },
        resetForm() {
            this.$refs.formEntry.reset()
        },
        initializeForm() {
            if (this.formType === 'CREATED') {
                this.form = {
                    code: undefined,
                    issue: undefined,
                    problem: undefined,
                    remarks: undefined,
                    terminalId: undefined,
                    status: undefined,
                    partsReplaced: undefined,
                    reportedBy: undefined,
                    assignedTo: undefined,
                    action: undefined,
                    ecodAction: undefined
                }
            } else if (this.formType === 'UPDATE' && this.selectedItem) {
                this.form = {
                    code: this.selectedItem.code,
                    issue: this.selectedItem.issue,
                    problem: this.selectedItem.problem,
                    remarks: this.selectedItem.remarks,
                    terminalId: this.selectedItem.terminalId,
                    status: this.selectedItem.status,
                    partsReplaced: this.selectedItem.partsReplaced,
                    reportedBy: this.selectedItem.reportedBy,
                    assignedTo: this.selectedItem.assignedTo,
                    action: this.selectedItem.action,
                    ecodAction: this.selectedItem.ecodAction
                }
            }
        },
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        onlyForCurrency ($event) {
            // console.log($event.keyCode); //keyCodes value
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);

            // only allow number and one dot
            if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || this.form.amount.indexOf('.') != -1)) { // 46 is dot
                $event.preventDefault();
            }

            // restrict to 2 decimal places
            if(this.form.amount!=null && this.form.amount.indexOf(".")>-1 && (this.form.amount.split('.')[1].length > 1)){
                $event.preventDefault();
            }
        },
        getStatus: async function (val) {
            console.log('val', val)
                this.form.status = val
        },
    }
}
</script>
